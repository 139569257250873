import './App.css';

function App() {
  return (
    <div className="App h-screen bg-slate-900 flex justify-center items-center">
      <div className='flex flex-col items-end'>
        <p className='text-5xl md:text-8xl font-bold text-fuchsia-400'>Live your life</p>
        <p className='text-3xl mt-5 md:mt-10 text-slate-300'>~ Sarandhoom 😎</p>
      </div>
    </div>
  );
}

export default App;
